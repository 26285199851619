import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ContentfulImage from '../contentful-image';

import styles from './AnimateImage.module.css';

const DIRECTIONS = {
    LEFT: 'left',
    RIGHT: 'right',
};

const TYPES = {
    SLIDE: 'slide',
    REVEAL: 'reveal',
};

export const AnimateImage = ({
    alt,
    src,
    type,
    className,
    direction,
    imageClassName,
    startAnimation,
}) => {
    const containerClasses = classNames(
        styles.container,
        styles[direction],
        styles[type],
        { [styles.visible]: startAnimation },
        className,
    );
    const imageClasses = classNames(styles.image, imageClassName);

    return (
        <div className={ containerClasses }>
            <ContentfulImage
                alt={ alt }
                src={ src }
                className={ imageClasses } />
        </div>
    );
};

AnimateImage.propTypes = {
    className: PropTypes.string,
    startAnimation: PropTypes.bool,
    imageClassName: PropTypes.string,
    alt: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(TYPES)),
    direction: PropTypes.oneOf(Object.values(DIRECTIONS)),
};

AnimateImage.defaultProps = {
    startAnimation: false,
    type: TYPES.REVEAL,
    direction: DIRECTIONS.LEFT,
};

export default AnimateImage;
