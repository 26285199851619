import React from 'react';
// import { Link } from 'gatsby';

// import { footerData } from '../../data';
import useTranslations from '../../hooks/translations';

import styles from './Footer.module.css';

export const Footer = () => {
    const translations = useTranslations();

    return (
        <footer className={ styles.footer }>
            {/* <Link className={ styles.link } to={ footerData.navigations.terms.to }>{ footerData.navigations.terms.text }</Link>
        <span className={ styles.separator }>|</span>
        <Link className={ styles.link } to={ footerData.navigations.privacyPolicy.to }>{ footerData.navigations.privacyPolicy.text }</Link>
        <span className={ styles.separator }>|</span>*/}
            <span>{ translations.copyright }</span>
        </footer>
    );
};

export default Footer;
