import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './AnimateTitle.module.css';

export const AnimateTitle = forwardRef(({ children, className, startAnimation }, ref) => {
    const containerClasses = classNames(
        styles.container,
        { [styles.visible]: startAnimation },
        className,
    );

    return (
        <div
            ref={ ref }
            className={ containerClasses }>
            { children }
        </div>
    );
});

AnimateTitle.propTypes = {
    className: PropTypes.string,
    startAnimation: PropTypes.bool,
    children: PropTypes.any.isRequired,
};

AnimateTitle.defaultProps = {
    startAnimation: false,
};

export default AnimateTitle;
