import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { NewsProvider } from '../../hooks/news';
import { ViewportProvider } from '../../hooks/viewport';
import { TranslationsProvider } from '../../hooks/translations';

const query = graphql`
    query AllNews {
      contentful {
        newsCollection(order: date_DESC, limit: 15) {
          items {
            title
            slug
            date
            image {
              description
              url
            }
            text {
              json
            }
            shortDescription
            category {
              identifier
              name
              shortName
            }
          }
        }
        genericTranslationsCollection(limit: 1) {
          items {
            seeMore
            next
            previous
            latestNews
            otherNews
            moreOf
            noNews
            readMore
            home
            contacts
            news
            practiceAreas
            team
            copyright
          }
        }
      }
    }
`;

export const RootElementWrapper = ({ children }) => {
    const {
        contentful: {
            newsCollection: { items: allNews },
            genericTranslationsCollection: { items: [translations] },
        },
    } = useStaticQuery(query);

    return (
        <ViewportProvider>
            <NewsProvider news={ allNews }>
                <TranslationsProvider
                    translations={ translations }>
                    { children }
                </TranslationsProvider>
            </NewsProvider>
        </ViewportProvider>
    );
};

RootElementWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default RootElementWrapper;
