import React, { useMemo } from 'react';
import { NavigationProvider, Navbar, Drawer } from '@moxy/react-navigation';

import { NavbarContent, DrawerContent } from './components';
import { navigations as defaultNavigations } from '../../data';
import useNews from '../../hooks/news';

import styles from './Header.module.css';

export const Header = () => {
    const { allNews } = useNews();
    const hasNews = !!allNews?.length;

    // Add home route for mobile navigation and/or remove news if there are no news
    const { desktopNavigations, mobileNavigations } = useMemo(() => {
        const navigations = defaultNavigations.filter((navigation) => navigation.id === 'news' ? hasNews : true);
        const mobileNavigationsCopy = [...navigations];

        mobileNavigationsCopy.unshift({
            id: 'home',
            translationKey: 'home',
        });

        return ({
            desktopNavigations: navigations,
            mobileNavigations: mobileNavigationsCopy,
        });
    }, [hasNews]);

    return (
        <header className={ styles.header }>
            <NavigationProvider>
                <Navbar
                    placement="top"
                    className={ styles.navbar }>
                    <NavbarContent navigations={ desktopNavigations } />
                    <Drawer
                        placement="left"
                        withOverlay={ false }
                        className={ styles.drawer }>
                        <DrawerContent navigations={ mobileNavigations } />
                    </Drawer>
                </Navbar>
            </NavigationProvider>
        </header>
    );
};

export default Header;
