import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';

import ContentfulImage from '../../../contentful-image';
import PracticeAreaBadge from '../../../practice-area-badge';
import useTranslations from '../../../../hooks/translations';

import styles from './NewsCard.module.css';

const NewsCard = ({
    text,
    date,
    title,
    link,
    image,
    category,
    className,
    highlighted,
}) => {
    const translations = useTranslations();

    const articleClasses = classNames(
        styles.article,
        { [styles.highlighted]: highlighted },
        className,
    );

    if (highlighted) {
        return (
            <Link to={ link } className={ styles.link }>
                <article className={ articleClasses }>
                    <div className={ styles.imageWrapper }>
                        <ContentfulImage
                            alt={ image.alt }
                            src={ image.url }
                            className={ styles.image } />
                    </div>
                    <div className={ styles.overlay }>
                        <h2 className={ styles.title }>{ title }</h2>
                        <p className={ styles.text }>{ text }</p>
                        <div className={ styles.info }>
                            <PracticeAreaBadge
                                name={ category }
                                className={ styles.categoryBadge } />
                            <span className={ styles.date }>{ date }</span>
                        </div>
                    </div>
                </article>
            </Link>
        );
    }

    return (
        <Link to={ link } className={ styles.link }>
            <article className={ articleClasses }>
                <div className={ styles.info }>
                    <span className={ styles.category }>{ category }</span>
                    <span className={ styles.date }>{ date }</span>
                </div>
                <h2 className={ styles.title }>{ title }</h2>
                <div className={ styles.imageWrapper }>
                    <ContentfulImage
                        alt={ image.alt }
                        src={ image.url }
                        className={ styles.image } />
                </div>
                <p className={ styles.text }>{ text }</p>
                <p className={ styles.readMore }>
                    { translations.readMore }
                </p>
            </article>
        </Link>
    );
};

NewsCard.propTypes = {
    className: PropTypes.string,
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    image: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    }).isRequired,
    highlighted: PropTypes.bool,
};

NewsCard.defaultProps = {
    highlighted: false,
};

export default NewsCard;
