import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';

import { practiceAreas, navigationMapper } from '../../data';
import ContentfulImage from '../contentful-image';
import PracticeAreaBadge from '../practice-area-badge';
import PhotoPlacholder from '../../media/images/team/placeholder.png';
import useTranslations from '../../hooks/translations';

import styles from './TeamMemberCard.module.css';

export const TeamMemberCard = ({
    slug,
    name,
    area,
    role,
    photoUrl,
    photoAlt,
    className,
    ...remainingProps
}) => {
    const translations = useTranslations();

    const memberPath = `${navigationMapper.team}/${slug}`;
    const mobileInfoClasses = classNames(styles.mobileInfo, styles[area.identifier]);

    return (
        <div className={ classNames(styles.container, className) } { ...remainingProps }>
            <div className={ styles.wrapper }>
                <ContentfulImage
                    alt={ photoAlt }
                    src={ photoUrl }
                    className={ styles.image } />
                <Link
                    to={ memberPath }
                    className={ mobileInfoClasses }>
                    <p className={ styles.mobileName }>{ name }</p>
                </Link>
                <div className={ styles.infoContainer }>
                    <p className={ styles.name }>{ name }</p>
                    <p className={ styles.role }>{ role }</p>
                    <div className={ styles.areaContainer }>
                        <PracticeAreaBadge
                            name={ area.name }
                            id={ area.identifier }
                            className={ styles.badge } />
                        <Link
                            to={ memberPath }
                            className={ styles.link }>
                            { translations.seeMore }
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

TeamMemberCard.propTypes = {
    photoUrl: PropTypes.string,
    photoAlt: PropTypes.string,
    className: PropTypes.string,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    area: PropTypes.shape({
        name: PropTypes.string.isRequired,
        identifier: PropTypes.oneOf(practiceAreas).isRequired,
    }).isRequired,
};

TeamMemberCard.defaultProps = {
    photoUrl: PhotoPlacholder,
};

export default TeamMemberCard;
