import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import NewsContext from './NewsContext';
import { CONSTANTS } from '../../utils';

export const NewsProvider = ({
    children,
    news: allNews,
    initialActiveCategory,
}) => {
    const [filterByCategory, setFilterByCategory] = useState(initialActiveCategory);

    const value = useMemo(() => {
        const filterNews = allNews.filter(
            (news) => news.category.identifier === filterByCategory?.toLowerCase(),
        );
        const filteredNews =
            !filterNews.length && filterByCategory === CONSTANTS.LATEST_NEWS_IDENTIFIER ?
                allNews :
                filterNews;

        return {
            allNews,
            filteredNews,
            activeCategory: filterByCategory,
            filterNewsByCategory: setFilterByCategory,
        };
    }, [allNews, filterByCategory]);

    return (
        <NewsContext.Provider value={ value }>
            { children }
        </NewsContext.Provider>
    );
};

NewsProvider.propTypes = {
    news: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired,
    initialActiveCategory: PropTypes.string,
};

export default NewsProvider;
