import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Categories from './categories';
import NewsList from './news-list';
import useNews from '../../../hooks/news';
import useViewport from '../../../hooks/viewport';

import styles from './Widget.module.css';

export const Widget = ({ className, categories }) => {
    const containerClasses = classNames(styles.container, className);
    const {
        filteredNews,
        activeCategory,
        filterNewsByCategory,
    } = useNews();

    const { isDesktop, isLargeDesktop } = useViewport();

    useEffect(() => {
        const shouldUpdateCategory = !categories.find((category) => category.identifier === activeCategory);

        if (isDesktop || isLargeDesktop) {
            if (!activeCategory || shouldUpdateCategory) {
                filterNewsByCategory(categories[0].identifier);
            }
        }
    }, [activeCategory, categories, filterNewsByCategory, isDesktop, isLargeDesktop]);

    return (
        <div className={ containerClasses }>
            <Categories
                items={ categories }
                activeCategory={ activeCategory }
                onCategoryClick={ filterNewsByCategory } />
            <NewsList news={ filteredNews } />
        </div>
    );
};

Widget.propTypes = {
    className: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.shape({
        shortName: PropTypes.string,
        name: PropTypes.string.isRequired,
        identifier: PropTypes.string.isRequired,
    })).isRequired,
};

export default Widget;
