import React from 'react';
import PropTypes from 'prop-types';

import templateMapping from './template-mapping';
import TranslationsContext from './TranslationsContext';

export const TranslationsProvider = ({ translations, children }) => {
    const finalTranslations = Object.entries(translations).reduce((acc, [key, value]) => {
        // Find template strings. E.g.: {currentYear}
        const match = value.match(/\{(.*?)\}/);

        if (!match) {
            acc[key] = value;

            return acc;
        }

        const [keywordWithBrackets, keyword] = match;

        acc[key] = value.replace(keywordWithBrackets, templateMapping[keyword]);

        return acc;
    }, {});

    return (
        <TranslationsContext.Provider value={ finalTranslations }>
            { children }
        </TranslationsContext.Provider>
    );
};

TranslationsProvider.propTypes = {
    children: PropTypes.node.isRequired,
    translations: PropTypes.object.isRequired,
};

export default TranslationsProvider;
