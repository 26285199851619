// Values are in Pixel unit
const BREAKPOINTS = {
    MOBILE: 480,
    TABLET: 768,
    SMALL_DESKTOP: 1024,
    DESKTOP: 1440,
    LARGE_DESKTOP: 1920,
};

export default BREAKPOINTS;
