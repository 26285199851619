import { useContext } from 'react';

import TranslationsContext from './TranslationsContext';

const useNews = () => {
    const value = useContext(TranslationsContext);

    return {
        ...value,
    };
};

export default useNews;
