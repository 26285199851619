import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';

import LogoImg from '../../media/images/logo.jpg';

import styles from './Logo.module.css';

export const Logo = ({ className }) => (
    <Link to="/" className={ classNames(styles.logoContainer, className) }>
        <img className={ styles.image } src={ LogoImg } alt="PA Advogados Logo" />
    </Link>
);

Logo.propTypes = {
    className: PropTypes.string,
};

export default Logo;
