import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import 'intersection-observer'; // Optional polyfill
import BaseObserver from '@researchgate/react-intersection-observer';

export const Observer = ({ children, ...props }) => {
    const [isVisible, setVisible] = useState(false);

    const handleChange = useCallback(({ isIntersecting }, unobserve) => {
        if (isIntersecting) {
            setVisible(true);
            unobserve();
        }
    }, []);

    return (
        <BaseObserver onChange={ handleChange } { ...props }>
            { typeof children === 'function' ? children(isVisible) : children }
        </BaseObserver>
    );
};

Observer.propTypes = {
    children: PropTypes.oneOfType(
        [PropTypes.node, PropTypes.func],
    ).isRequired,
};

export default Observer;
