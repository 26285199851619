import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useNavigation } from '@moxy/react-navigation';

import Logo from '../../../logo';
import HamburguerButton from '../hamburguer-button';
import Navigations from '../navigations';

import styles from './NavbarContent.module.css';

export const NavbarContent = ({ className, navigations }) => {
    const { drawer } = useNavigation();

    return (
        <div className={ classNames(styles.content, className) }>
            <Logo />
            <Navigations
                items={ navigations }
                className={ styles.navigations } />
            <HamburguerButton
                open={ drawer.isOpen }
                openDrawer={ drawer.open }
                closeDrawer={ drawer.close }
                toggleDrawer={ drawer.toggle }
                className={ styles.menuButton } />
        </div>
    );
};

NavbarContent.propTypes = {
    className: PropTypes.string,
    navigations: PropTypes.array.isRequired,
};

export default NavbarContent;
