import { useContext } from 'react';

import NewsContext from './NewsContext';

const useNews = () => {
    const value = useContext(NewsContext);

    return {
        ...value,
    };
};

export default useNews;
