import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Layout from '../layout';
import DesktopNewsWidget from './widget';
import TabletNewsWidget from './tablet-widget';
import useTranslations from '../../hooks/translations';

import styles from './NewsWidgetLayout.module.css';

export const NewsWidgetLayout = ({
    layout,
    children,
    className,
    allCategories,
}) => {
    const translations = useTranslations();

    const splittedPathname = layout.location.pathname.split('/');
    const isNewsPage = splittedPathname.length < 3 ? false : splittedPathname[2] !== '';

    const layoutClasses = classNames(styles.main, className);
    const tabletWidgetClasses = classNames(styles.tabletWidget, {
        [styles.hidden]: isNewsPage,
    });

    return (
        <Layout
            seoData={ layout.seoData }
            className={ layoutClasses }
            location={ layout.location }>
            <TabletNewsWidget
                categories={ allCategories }
                className={ tabletWidgetClasses } />
            { children }
            <div className={ styles.widgetContainer }>
                <div className={ styles.stickyContainer }>
                    <p className={ styles.widgetTitle }>{ translations.otherNews }</p>
                    <DesktopNewsWidget categories={ allCategories } />
                </div>
            </div>
        </Layout>
    );
};

NewsWidgetLayout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    previousActiveCategory: PropTypes.string,
    allCategories: PropTypes.array.isRequired,
    layout: PropTypes.shape({
        seoData: PropTypes.object,
        location: PropTypes.object,
    }),
};

NewsWidgetLayout.defaultProps = {
    layout: {},
};

export default NewsWidgetLayout;
