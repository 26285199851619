import React, { useEffect, useCallback } from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Categories from '../widget/categories';
import useNews from '../../../hooks/news';
import useViewport from '../../../hooks/viewport';
import useTranslations from '../../../hooks/translations';
import { navigationMapper } from '../../../data';
import useLocation from '../../layout/useLocation';
import { CONSTANTS } from '../../../utils';

import styles from './TabletWidget.module.css';

export const TabletWidget = ({
    className,
    categories,
}) => {
    const translations = useTranslations();
    const { activeCategory, filterNewsByCategory } = useNews();
    const { isMobile, isTablet, isSmallDesktop } = useViewport();
    const { pathname } = useLocation();

    const allCategories = [...categories];

    const latestNewsCategory = {
        name: translations.latestNews,
        identifier: CONSTANTS.LATEST_NEWS_IDENTIFIER,
    };

    allCategories.unshift(latestNewsCategory);

    useEffect(() => {
        if (isMobile || isTablet || isSmallDesktop) {
            !activeCategory && filterNewsByCategory(allCategories[0].identifier);
        }
    }, [filterNewsByCategory, allCategories, activeCategory, isMobile, isTablet, isSmallDesktop]);

    const containerClasses = classNames(styles.container, className);

    const handleCategoryClick = useCallback((categoryId) => {
        filterNewsByCategory(categoryId);

        pathname !== navigationMapper.news && navigate(navigationMapper.news);
    }, [filterNewsByCategory, pathname]);

    return (
        <Categories
            fullName
            items={ allCategories }
            className={ containerClasses }
            activeCategory={ activeCategory }
            onCategoryClick={ handleCategoryClick }
            categoriesClassName={ styles.categories } />
    );
};

TabletWidget.propTypes = {
    className: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.shape({
        shortName: PropTypes.string,
        name: PropTypes.string.isRequired,
        identifier: PropTypes.string.isRequired,
    })).isRequired,
};

export default TabletWidget;
