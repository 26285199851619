import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import ViewportContext from './ViewportContext';
import { breakpoints } from '../../data';

const initialViewportValues = {
    isMobile: false,
    isTablet: false,
    isSmallDesktop: false,
    isDesktop: false,
    isLargeDesktop: false,
};

export const ViewportProvider = ({ children }) => {
    const [viewportValues, setViewportValues] = useState(initialViewportValues);

    const computeViewportValues = useCallback((width) => {
        if (width <= breakpoints.MOBILE) {
            !viewportValues.isMobile &&
                setViewportValues({
                    isMobile: true,
                    isTablet: false,
                    isSmallDesktop: false,
                    isDesktop: false,
                    isLargeDesktop: false,
                });
        } else if (width <= breakpoints.TABLET) {
            !viewportValues.isTablet &&
                setViewportValues({
                    isMobile: false,
                    isTablet: true,
                    isSmallDesktop: false,
                    isDesktop: false,
                    isLargeDesktop: false,
                });
        } else if (width <= breakpoints.SMALL_DESKTOP) {
            !viewportValues.isSmallDesktop &&
                setViewportValues({
                    isMobile: false,
                    isTablet: false,
                    isSmallDesktop: true,
                    isDesktop: false,
                    isLargeDesktop: false,
                });
        } else if (width <= breakpoints.DESKTOP) {
            !viewportValues.isDesktop &&
                setViewportValues({
                    isMobile: false,
                    isTablet: false,
                    isSmallDesktop: false,
                    isDesktop: true,
                    isLargeDesktop: false,
                });
        } else {
            !viewportValues.isLargeDesktop &&
                setViewportValues({
                    isMobile: false,
                    isTablet: false,
                    isSmallDesktop: false,
                    isDesktop: false,
                    isLargeDesktop: true,
                });
        }
    }, [
        viewportValues.isDesktop,
        viewportValues.isLargeDesktop,
        viewportValues.isMobile,
        viewportValues.isSmallDesktop,
        viewportValues.isTablet,
    ]);

    const handleWindowResize = useCallback(() => {
        computeViewportValues(window.innerWidth);
    }, [computeViewportValues]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        computeViewportValues(window.innerWidth);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [computeViewportValues, handleWindowResize]);

    return (
        <ViewportContext.Provider value={ viewportValues }>
            { children }
        </ViewportContext.Provider>
    );
};

ViewportProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ViewportProvider;
