import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './AnimateText.module.css';

export const AnimateText = ({
    children,
    className,
    startAnimation,
    ...rest
}) => {
    const textClasses = classNames(
        styles.text,
        { [styles.visible]: startAnimation },
        children.props.className,
        className,
    );

    return cloneElement(children, { className: textClasses, ...rest });
};

AnimateText.propTypes = {
    className: PropTypes.string,
    startAnimation: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

AnimateText.defaultProps = {
    startAnimation: false,
};

export default AnimateText;
