import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Navigations from '../navigations';

import styles from './DrawerContent.module.css';

export const DrawerContent = ({ className, navigations }) => (
    <Navigations
        items={ navigations }
        linkClassName={ styles.link }
        listItemClassName={ styles.listItem }
        className={ classNames(styles.navigations, className) } />
);

DrawerContent.propTypes = {
    className: PropTypes.string,
    navigations: PropTypes.array.isRequired,
};

export default DrawerContent;
