import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { format } from 'timeago.js';
import { Link } from 'gatsby';

import styles from './NewsCard.module.css';

export const NewsCard = ({
    date,
    title,
    linkTo,
    category,
    className,
}) => {
    const cardClasses = classNames(styles.container, className);

    if (linkTo) {
        return (
            <Link
                to={ linkTo }
                className={ cardClasses }>
                <p className={ styles.title }>{ title }</p>
                { category &&
                    <span className={ styles.category }>{ category }</span> }
                { date && (
                    <span className={ styles.date }>
                        { format(new Date(date), 'pt_BR') }
                    </span>
                ) }
            </Link>
        );
    }

    return (
        <div className={ cardClasses }>
            <p className={ styles.title }>{ title }</p>
            { category &&
            <span className={ styles.category }>{ category }</span> }
            { date && (
                <span className={ styles.date }>
                    { format(new Date(date), 'pt_BR') }
                </span>
            ) }
        </div>
    );
};

NewsCard.propTypes = {
    date: PropTypes.string,
    linkTo: PropTypes.string,
    category: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
};

export default NewsCard;
