import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './HamburguerButton.module.css';

export const HamburguerButton = ({
    open,
    className,
    openDrawer,
    closeDrawer,
    toggleDrawer,
    ...props
}) => {
    const buttonClasses = classNames(
        styles.button,
        { [styles.open]: open },
        className,
    );

    const handleClick = useCallback(() => {
        toggleDrawer && toggleDrawer();
    }, [toggleDrawer]);

    const handleKeyDown = useCallback(({ key, shiftKey, nativeEvent }) => {
        switch (key.toLowerCase()) {
        case 'arrowdown':
            nativeEvent.preventDefault();
            openDrawer && openDrawer();
            break;
        case 'tab':
            closeDrawer && shiftKey && closeDrawer();
            break;
        default:
            break;
        }
    }, [openDrawer, closeDrawer]);

    return (
        <button
            aria-haspopup="true"
            aria-expanded={ open }
            onClick={ handleClick }
            className={ buttonClasses }
            onKeyDown={ handleKeyDown }
            { ...props }>
            <span className={ styles.bar } />
        </button>
    );
};

HamburguerButton.propTypes = {
    open: PropTypes.bool,
    className: PropTypes.string,
    openDrawer: PropTypes.func,
    closeDrawer: PropTypes.func,
    toggleDrawer: PropTypes.func,
};

HamburguerButton.defaultProps = {
    open: false,
};

export default HamburguerButton;
