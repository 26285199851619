export const navigationMapper = {
    practiceAreas: '/practice-areas',
    team: '/team',
    news: '/news',
    contact: '/contact',
    home: '/',
};

export const navigations = [
    {
        id: 'practiceAreas',
        translationKey: 'practiceAreas',
    },
    {
        id: 'team',
        translationKey: 'team',
    },
    {
        id: 'news',
        translationKey: 'news',
    },
    {
        id: 'contact',
        translationKey: 'contacts',
    },
];
