import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const defaultMetadataQuery = graphql`
    {
      site {
        siteMetadata {
          title
          description
          baseUrl
          keywords
        }
      }
    }
`;

export const SEO = (props) => {
    const defaultMetadata = useStaticQuery(defaultMetadataQuery).site.siteMetadata;

    if (defaultMetadata.baseUrl === '' && typeof window !== 'undefined') {
        defaultMetadata.baseUrl = window.location.origin;
    }

    const title = props.title || defaultMetadata.title;
    const description = props.description || defaultMetadata.description;
    const url = new URL(props.path || '', defaultMetadata.baseUrl);
    const imagePath = props.image || false;
    const keywords = defaultMetadata.keywords;

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <link rel="canonical" href={ url } />
            <meta name="title" content={ title } />
            <meta name="description" content={ description } />
            { keywords && <meta name="keywords" content={ keywords } /> }
            { imagePath && <meta name="image" content={ imagePath } /> }

            {/* Open Graph / Facebook*/}
            <meta property="og:url" content={ url } />
            {
                props.article ?
                    <meta property="og:type" content="article" /> :
                    <meta property="og:type" content="website" />
            }
            <meta property="og:title" content={ title } />
            <meta property="og:description" content={ description } />
            { imagePath && <meta property="og:image" content={ imagePath } /> }

            { /* Twitter */ }
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={ url } />
            <meta property="twitter:title" content={ title } />
            <meta property="twitter:description" content={ description } />
            { imagePath && <meta property="twitter:image" content={ imagePath } /> }

            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#cb3232" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="theme-color" content="#ffffff" />
        </Helmet>
    );
};

SEO.propTypes = {
    path: PropTypes.string,
    title: PropTypes.string,
    article: PropTypes.bool,
    image: PropTypes.string,
    description: PropTypes.string,
};

SEO.defaultProps = {
    article: false,
};

export default SEO;
