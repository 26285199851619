import { useContext } from 'react';

import ViewportContext from './ViewportContext';

const useViewport = () => {
    const value = useContext(ViewportContext);

    return {
        ...value,
    };
};

export default useViewport;
