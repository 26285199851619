import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AnimateImage from '../animate-image';
import AnimateText from '../animate-text';
import Observer from '../observer';

import styles from './PhotoContent.module.css';

const POSITION = {
    LEFT: 'left',
    RIGHT: 'right',
};

export const PhotoContent = ({
    src,
    alt,
    children,
    className,
    imageAlignment,
    textContentClassName,
    imageContentClassName,
    imageAnimation: {
        type: imageAnimationType,
        direction: imageAnimationDirection,
    },
}) => {
    const containerClasses = classNames(
        styles.container,
        {
            [styles.right]: imageAlignment === POSITION.RIGHT,
        },
        className,
    );
    const imageContentClasses = classNames(styles.imageContent, imageContentClassName);
    const textContentClasses = classNames(styles.textContent, textContentClassName);

    return (
        <Observer threshold={ 0.3 }>
            { (isVisible) => (
                <div className={ containerClasses }>
                    <div className={ imageContentClasses }>
                        <AnimateImage
                            src={ src }
                            alt={ alt }
                            className={ styles.image }
                            type={ imageAnimationType }
                            startAnimation={ isVisible }
                            direction={ imageAnimationDirection } />
                    </div>
                    <div className={ textContentClasses }>
                        <AnimateText startAnimation={ isVisible }>
                            { children }
                        </AnimateText>
                    </div>
                </div>
            ) }
        </Observer>
    );
};

PhotoContent.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    textContentClassName: PropTypes.string,
    imageContentClassName: PropTypes.string,
    imageAlignment: PropTypes.oneOf(Object.values(POSITION)),
    imageAnimation: PropTypes.shape({
        direction: PropTypes.string,
        type: PropTypes.string,
    }),
};

PhotoContent.defaultProps = {
    imageAlignment: POSITION.LEFT,
    imageAnimation: {},
};

export default PhotoContent;
