import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';

import { navigationMapper } from '../../../../data';
import useLocation from '../../../layout/useLocation';
import useTranslations from '../../../../hooks/translations';

import styles from './Navigations.module.css';

export const Navigations = ({
    items,
    className,
    linkClassName,
    listItemClassName,
}) => {
    const translations = useTranslations();

    const { pathname } = useLocation();
    const [_, currentPage] = pathname.split('/'); // eslint-disable-line no-unused-vars

    return (
        useMemo(() => (
            <ul className={ classNames(styles.list, className) }>
                { items.map(({ id, translationKey }, index) => (
                    <li
                        key={ `navigation-${index}` }
                        className={ classNames(styles.listItem, listItemClassName) }>
                        <Link
                            to={ navigationMapper[id] }
                            className={ classNames(
                                styles.link,
                                { [styles.selected]: `/${currentPage}` === navigationMapper[id] },
                                linkClassName,
                            ) }>
                            { translations[translationKey] }
                        </Link>
                    </li>
                )) }
            </ul>
        ), [className, items, listItemClassName, currentPage, linkClassName, translations])
    );
};

Navigations.propTypes = {
    className: PropTypes.string,
    items: PropTypes.array.isRequired,
    listItemClassName: PropTypes.string,
};

export default Navigations;
