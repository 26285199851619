import 'normalize.css';
import './src/shared/styles/index.css';

import React from 'react';
import { register } from 'timeago.js';

import { portugueseTimeago } from './src/shared/data';
import { RootElementWrapper } from './src/shared/components';

register('pt_BR', portugueseTimeago);

export const wrapRootElement = ({ element }) => (
    <RootElementWrapper>
        { element }
    </RootElementWrapper>
);
