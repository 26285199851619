// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-practice-areas-js": () => import("./../../../src/pages/practice-areas.js" /* webpackChunkName: "component---src-pages-practice-areas-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-shared-pages-news-news-js": () => import("./../../../src/shared/pages/news/News.js" /* webpackChunkName: "component---src-shared-pages-news-news-js" */),
  "component---src-shared-pages-team-member-team-member-js": () => import("./../../../src/shared/pages/team-member/TeamMember.js" /* webpackChunkName: "component---src-shared-pages-team-member-team-member-js" */)
}

