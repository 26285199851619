import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import KeyboardOnlyOutlines from '@moxy/react-keyboard-only-outlines';

import Seo from '../seo';
import Footer from '../footer';
import Header from '../header';
import LocationContext from './useLocation/context';

import styles from './Layout.module.css';

export const Layout = ({
    children,
    seoData,
    location,
    className,
}) => (
    <KeyboardOnlyOutlines>
        <LocationContext.Provider value={ location }>
            <div className={ styles.page }>
                <Seo
                    title={ seoData.title }
                    image={ seoData.image }
                    path={ location.pathname }
                    article={ seoData.article }
                    description={ seoData.description } />
                <Header />
                <main className={ classNames(styles.main, className) }>
                    { children }
                </main>
                <Footer />
            </div>
        </LocationContext.Provider >
    </KeyboardOnlyOutlines>
);

Layout.propTypes = {
    seoData: PropTypes.shape({
        path: PropTypes.string,
        title: PropTypes.string,
        article: PropTypes.bool,
        image: PropTypes.string,
        description: PropTypes.string,
    }),
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    location: PropTypes.object.isRequired,
};

export default Layout;
