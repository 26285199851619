import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { navigationMapper } from '../../../../data';
import useTranslations from '../../../../hooks/translations';

import NewsCard from './news-card';

import styles from './NewsList.module.css';

export const NewsList = ({ news, className }) => {
    const translations = useTranslations();

    const listClasses = classNames(styles.list, className);

    if (!news?.length) {
        return (
            <NewsCard
                className={ styles.noNews }
                title={ translations.noNews } />
        );
    }

    return (
        <ul className={ listClasses }>
            { news && news.map((item, index) => {
                const link = `${navigationMapper.news}/${item.slug}`;

                return (
                    <li
                        key={ `list-card-${index}` }
                        className={ styles.listItem }>
                        <NewsCard
                            linkTo={ link }
                            date={ item.date }
                            title={ item.title }
                            category={ item.category.name } />
                    </li>
                );
            }) }
        </ul>
    );
};

NewsList.propTypes = {
    className: PropTypes.string,
    news: PropTypes.array.isRequired,
};

export default NewsList;
