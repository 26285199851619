import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Arrow.module.css';

const DIRECTIONS = {
    UP: 'up',
    DOWN: 'down',
    LEFT: 'left',
    RIGHT: 'right',
};

const Arrow = ({ className, direction, ...rest }) => {
    const arrowClasses = classNames(
        styles.arrow,
        styles[direction],
        className,
    );

    return (
        <div
            className={ arrowClasses }
            { ...rest } />
    );
};

Arrow.propTypes = {
    className: PropTypes.string,
    direction: PropTypes.oneOf(Object.values(DIRECTIONS)),
};

Arrow.defaultProps = {
    direction: DIRECTIONS.DOWN,
};

export default Arrow;
