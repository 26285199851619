import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { format } from 'timeago.js';

import { navigationMapper } from '../../data';
import { NewsCard } from './components';
import useTranslations from '../../hooks/translations';

import styles from './NewsGrid.module.css';

const MAX_NEWS = 15;

const NewsGrid = ({
    news,
    isFirstHighlighted,
    className,
}) => {
    const translations = useTranslations();

    const listClasses = classNames(
        styles.newsList,
        { [styles.firstHighlighted]: isFirstHighlighted },
        className,
    );
    const slicedNews = news && news.slice(0, MAX_NEWS);

    if (!slicedNews?.length) {
        return (
            <p className={ styles.noNews }>
                { translations.noNews }
            </p>
        );
    }

    const renderedNewsItems = slicedNews && slicedNews.map((newsItem, index) => {
        const {
            date,
            title,
            slug,
            image,
            category,
            shortDescription,
        } = newsItem;

        const link = `${navigationMapper.news}/${slug}`;

        const cardImage = {
            alt: image.description,
            url: image.url,
        };

        return (
            <li
                key={ `news-card-${index}` }
                className={ styles.newsItemContainer }>
                <NewsCard
                    link={ link }
                    title={ title }
                    image={ cardImage }
                    text={ shortDescription }
                    category={ category.name }
                    date={ format(new Date(date), 'pt_BR') }
                    highlighted={ isFirstHighlighted && index === 0 } />
            </li>
        );
    });

    return (
        <ul className={ listClasses }>
            { renderedNewsItems }
        </ul>
    );
};

NewsGrid.propTypes = {
    className: PropTypes.string,
    news: PropTypes.array.isRequired,
    isFirstHighlighted: PropTypes.bool,
};

NewsGrid.defaultProps = {
    isFirstHighlighted: true,
};

export default NewsGrid;
