import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { PracticeAreaBadge } from '../../../../components';

import styles from './Categories.module.css';

export const Categories = ({
    items,
    fullName,
    className,
    activeCategory,
    categoriesClassName,
    onCategoryClick,
}) => {
    const containerClasses = classNames(styles.categoriesContainer, className);
    const categoriesClasses = classNames(styles.categories, categoriesClassName);

    const handleBadgeClick = useCallback((event) => {
        const { categoryId } = event.currentTarget.dataset;

        onCategoryClick && onCategoryClick(categoryId);
    }, [onCategoryClick]);

    const renderedCategories = items.map((category, index) => {
        const categoryName = fullName ?
            category.name :
            category?.shortName ?? category.name;

        const categoryClasses = classNames(styles.category, {
            [styles.notActive]: activeCategory !== category.identifier,
        });

        return (
            <PracticeAreaBadge
                key={ index }
                name={ categoryName }
                onClick={ handleBadgeClick }
                className={ categoryClasses }
                data-category-id={ category.identifier } />
        );
    });

    return (
        <div className={ containerClasses }>
            <div className={ categoriesClasses }>
                { renderedCategories }
            </div>
        </div>
    );
};

Categories.propTypes = {
    fullName: PropTypes.bool,
    className: PropTypes.string,
    activeCategory: PropTypes.string,
    onCategoryClick: PropTypes.func,
    categoriesClassName: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        shortName: PropTypes.string,
        name: PropTypes.string.isRequired,
        identifier: PropTypes.string.isRequired,
    })).isRequired,
};

Categories.defaultProps = {
    fullName: false,
};

export default Categories;
