import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { practiceAreas } from '../../data';

import styles from './PracticeAreaBadge.module.css';

export const PracticeAreaBadge = ({ className, id, name, ...rest }) => {
    const badgeClasses = classNames(
        styles.badge,
        styles[id],
        className,
    );

    return (
        <span className={ badgeClasses } { ...rest }>
            { name }
        </span>
    );
};

PracticeAreaBadge.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    id: PropTypes.oneOf(practiceAreas),
};

PracticeAreaBadge.defaultProps = {
    id: 'other',
};

export default PracticeAreaBadge;
