import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import AnimateText from '../animate-text';

export const RichTextToHtml = ({
    renderNodes,
    children,
    startAnimation,
    withTextAnimation,
}) => {
    const options = useMemo(() => withTextAnimation ?
        ({
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => (
                    <AnimateText startAnimation={ startAnimation }>
                        <p>{children}</p>
                    </AnimateText>
                ),
                ...renderNodes,
            },
        }) :
        ({ renderNode: renderNodes }),
    [withTextAnimation, renderNodes, startAnimation]);

    return withTextAnimation || renderNodes ?
        documentToReactComponents(children, options) :
        documentToReactComponents(children);
};

RichTextToHtml.propTypes = {
    renderNodes: PropTypes.object,
    startAnimation: PropTypes.bool,
    withTextAnimation: PropTypes.bool,
    children: PropTypes.object.isRequired,
};

RichTextToHtml.defaultProps = {
    startAnimation: false,
    withTextAnimation: false,
};

export default RichTextToHtml;
