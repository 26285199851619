import { useContext } from 'react';
import LocationContext from './context';

const useLocation = () => {
    const location = useContext(LocationContext);

    return {
        ...location,
    };
};

export default useLocation;
